import LabelField from "../Elements/LabelField";
import Button from "../Elements/Button";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { verifyOtp } from "../Services/Auth/Login";
import Alert from "react-bootstrap/Alert";

export default function VerifyOtp() {

    const navigate = useNavigate();

    const [otp, setOtp] = useState(null);
    const [validForm, setValidForm] = useState(false);
    const [alert, setAlert] = useState({ state: false, text: "", variant: "" });

    useEffect(() => {
        if (!localStorage.getItem("historyId") || !localStorage.getItem("mobileForOtp")) {
            navigate("/login");
        }
    }, [])

    useEffect(() => {
        if (!otp || otp.length < 4) {
            setValidForm(false);
        } else {
            setValidForm(true);
        }
    }, [otp])

    const handleOtp = (e) => {
        setOtp(e.target.value);
    }

    const handleVerifyOtp = async () => {
        const mobile = localStorage.getItem("mobileForOtp");
        const history_id = localStorage.getItem("historyId");
        const { data } = await verifyOtp({ mobile, otp, history_id })
        if (data.success) {
            navigate("/");
            localStorage.setItem("authToken", data.token);
            localStorage.removeItem("mobileForOtp");
            localStorage.removeItem("historyId");
        } else {
            setAlert({ state: true, text: data.message, variant: "danger" });
        }
    }



    return <div className="mc-register-container">

        <img className="mc-cover-image" src="/images/peakpx.png" alt="" />

        <div className="mc-register-component">
            <img src={" " || "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5O4SaH3D_AUzm0M9Zm2v3rGryM0nrpW5CLw&usqp=CAU"} alt="" />
            <div className="mc-register-form-container">
                {alert.state && <Alert variant={alert?.variant} dismissible>{alert?.text}</Alert>}
                <h2 className="mc-register-form-heading">OTP verification</h2>
                {/* <p className="mc-register-form-text">We have sent you an One Time Password on your mobile.</p> */}
                <div className="mc-register-form">
                    <LabelField
                        // label="OTP"
                        maxLength={4}
                        name="otp"
                        type="text"
                        defaultValue={""}
                        onChange={handleOtp}
                    />
                    <div className="flex">
                        <Button
                            disabled={!validForm}
                            className="mc-send-otp"
                            place="center"
                            text="Submit"
                            variant="primary"
                            // icon={"fa-solid fa-lock"}
                            onClick={handleVerifyOtp}
                        />
                    </div>
                </div>

                {/* <h2 className="login-link">New here ? <span className="highlight"><a href="/register"> Register</a></span></h2> */}
                {/* <p className="privacy-policy">By continuing, you agree to [this store] <span className="highlight">Conditions of Use</span> and <span className="highlight">Privacy Notice.</span></p> */}
            </div>
        </div>
    </div >
}