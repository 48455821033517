import { useEffect } from "react"
import Banners from "../Components/Carousel"
import Category from "../Components/Categories"
import Poster from "../Components/Poster"
import Slider from "../Components/Slider"
import Footer from "../Layout/Footer"
import Header from "../Layout/Header"
import { useNavigate } from "react-router-dom"
import { saveScore } from "../Services/Score/Scores"
import { logger } from "../Logger/logger"

export const Home = () => {

    const navigate = useNavigate();

    useEffect(() => {

        setTimeout(() => {

            if (!localStorage.getItem("authToken")) {
                navigate("/login");
            };

            if (!localStorage.getItem("mobile")) {
                navigate("/login");
            }
        }, 1000)
    }, []);

    setTimeout(() => {

        if (!localStorage.getItem("authToken")) {
            navigate("/login");
        };

        if (!localStorage.getItem("mobile")) {
            navigate("/login");
        }
    }, 1000)





    function downloadCSV(data, filename) {
        const csv = convertToCSV(data);
        const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(csvData);
        link.setAttribute('download', filename);
        link.click();
    }

    function convertToCSV(data) {
        const headers = Object.keys(data[0]).join(',') + '\n';
        const rows = data.map(row => Object.values(row).join(',') + '\n');
        return headers + rows.join('');
    }

    const data = [
        { name: 'John', age: 25, city: 'New York' },
        { name: 'Jane', age: 30, city: 'London' },
    ];

    const handleExportClick = () => {
        downloadCSV(data, 'data.csv');
    };


    useEffect(() => {

        const iframe = document.querySelector(".mc-iframe-game");
        console.log(iframe);

        // Add an event listener to the iframe's content window
        iframe.contentWindow.addEventListener("message", async (event) => {
            console.log(event);
            console.log(event.data.event == "gameOver");
            // Check if the message is from the trusted source (using event.origin)
            if (event.origin === `https://almondvirtex.s3.ap-south-1.amazonaws.com/CEAT_Cricket/index.html`) {
                const receivedMessage = event.data;

                if (event.data.event == "gameOver") {
                    try {
                        const response = await saveScore(event.data.score);
                        console.log({ response });

                        console.log(response.status, response.status === 403);

                        if (response.status === 403) {
                            localStorage.removeItem("authToken");
                            navigate("/login");
                        }

                    } catch (err) {
                        localStorage.removeItem("authToken");
                        navigate("/login");
                    }
                }
            }
        });
    }, [])


    const handleSaveScore = async (score) => {
        try {
            const response = await saveScore(score);
            console.log(response);
        } catch (err) {
            console.log(err.message);
        }
    };

    useEffect(() => {
        const handleMessage = async (e) => {
            // Handle the received message here
            const event = e.data;

            console.log(event);

            if (event.event == "gameOver") {


                logger("GAME_OVER_EVENT", {
                    tag: "GAME_OVER", data: {
                        event,
                        token: localStorage.getItem("authToken"),
                        mobile: localStorage.getItem("mobile")
                    }
                });


                localStorage.setItem("lastScores", JSON.stringify(event.score));
                const response = await saveScore(event.score);
                console.log({ response });

                logger("SAVE_SCORE_RESPONSE", {
                    tag: "SAVE_SCORE", data: {
                        response,
                        token: localStorage.getItem("authToken"),
                        mobile: localStorage.getItem("mobile")
                    }
                });

                if (response.status === 403) {
                    localStorage.removeItem("authToken");
                    navigate("/login");
                }
            }

            if (event.event == "gotoLeaderboard") {
                navigate("/leaderboard");
            }


            if (event.event == "requestFullScreen") {
                requestFullScreen();
            }

            if (event.event == "exitFullScreen") {
                exitFullScreen();
            }

            if (event.event == "logout") {
                logoutUser();
            }

            if (event.event == "gameStart") {
                if (localStorage.getItem("screen") == "requestedFull") {
                    // requestFullScreen();
                } else if (localStorage.getItem("screen") == "requestedOut") {
                    // exitFullScreen();
                }
            }
        };

        // Add the event listener
        window.addEventListener('message', handleMessage);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);


    useEffect(() => {
        document.addEventListener("fullscreenchange", function () {
            if (document.fullscreenElement === null) {
                // Full screen was exited, and you can handle it here.
                console.log("Full screen exited!");
                // You can perform any necessary actions here when full screen is exited.
            }
        });

        // You can also check for vendor-prefixed versions of the event
        document.addEventListener("webkitfullscreenchange", function () {
            if (document.webkitFullscreenElement === null) {
                console.log("Full screen exited in WebKit!");
            }
        });

        document.addEventListener("mozfullscreenchange", function () {
            if (document.mozFullScreenElement === null) {
                console.log("Full screen exited in Mozilla!");
            }
        });

        document.addEventListener("msfullscreenchange", function () {
            if (document.msFullscreenElement === null) {
                console.log("Full screen exited in Microsoft Edge!");
            }
        });

    }, [])

    const logoutUser = () => {
        localStorage.removeItem("authToken");
        navigate("/login");
    }

    const requestFullScreen = () => {
        try {
            const iframe = document.querySelector(".mc-iframe-game");

            // Check if the document is already in fullscreen
            if (
                document.fullscreenElement ||
                document.mozFullScreenElement ||
                document.webkitFullscreenElement ||
                document.msFullscreenElement
            ) {
                // An element is already in fullscreen mode, do not execute the request again
                console.log("Already in fullscreen.");
                exitFullScreen();
            } else {
                localStorage.setItem("screen", "requestedFull");

                // Check if the iframe supports fullscreen
                if (iframe.requestFullscreen) {
                    iframe.requestFullscreen();
                } else if (iframe.mozRequestFullScreen) { // Firefox
                    iframe.mozRequestFullScreen();
                } else if (iframe.webkitRequestFullscreen) { // Chrome, Safari, and Opera
                    iframe.webkitRequestFullscreen();
                } else if (iframe.msRequestFullscreen) { // IE/Edge
                    iframe.msRequestFullscreen();
                } else if (iframe.webkitEnterFullscreen) {
                    iframe.webkitEnterFullscreen(); //for iphone this code worked
                } else {
                    console.error("Fullscreen API is not supported in this browser.");
                }
            }
        } catch (err) {
            console.log(err.message);
        }
    };







    const exitFullScreen = () => {
        try {
            const iframe = document.querySelector(".mc-iframe-game");

            console.log("I am logging");
            localStorage.setItem("screen", "requestedOut");

            if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) { // Firefox
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) { // Chrome, Safari, and Opera
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { // IE/Edge
                    document.msExitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen(); //for iphone this code worked
                }
            }

            if (iframe.exitFullscreen) {
                iframe.exitFullscreen();
            } else if (iframe.mozCancelFullScreen) { // Firefox
                iframe.mozCancelFullScreen();
            } else if (iframe.webkitExitFullscreen) { // Chrome, Safari, and Opera
                iframe.webkitExitFullscreen();
            } else if (iframe.msExitFullscreen) { // IE/Edge
                iframe.msExitFullscreen();
            } else if (iframe.webkitExitFullscreen) {
                iframe.webkitExitFullscreen(); //for iphone this code worked
            }
        } catch (err) {
            console.log(err.message);
        }
    };


    useEffect(() => {
        const iframe = document.querySelector('.mc-iframe-game');
        const loader = document.querySelector('.loader');

        iframe.addEventListener('load', () => {
            // Hide the loader when the iframe's content has finished loading.
            loader.style.display = 'none';
        });
    }, [])

    return <div className="mc-iframe-wrapper">
        {/* <Header />
        <Category />
        <Banners />
        <Slider heading={"Top Deals"} id={"slide-1"} />
        <Poster />
        <Slider heading={"Top Sales"} id={"slide-2"} />
        <Footer /> */}


        <div class="iframe-container">
            <div class="loader"></div>
            <iframe className="mc-iframe-game" src={`https://almondvirtex.s3.ap-south-1.amazonaws.com/CEAT_Cricket/index.html`} width="800" height="600"></iframe>
            {/* <iframe className="mc-iframe-game" src={`newGame/index.html`} width="800" height="600"></iframe> */}
        </div>



    </div>
}