import axios from "axios";
import { baseurl } from "../../constants";


export const saveScore = async (score) => {
    try {
        const response = await axios.post(`${baseurl}/api/score`, { score }, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
            }
        });
        return response;
    } catch (err) {
        return err.response;
    }
};


export const getLeaderBoardScores = async (page, limit) => {
    try {
        const response = await axios.get(`${baseurl}/api/score/totals?page=${page}&limit=${limit}`);
        return response;
    } catch (err) {
        return err.message;
    }
}
