import LabelField from "../Elements/LabelField";
import Button from "../Elements/Button";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from '@leecheuk/react-google-login';
import { useEffect, useState } from "react";
import { loginWithGoogle, sendOtp, verifyOtp } from "../Services/Auth/Login";
import Alert from "react-bootstrap/Alert";
import GoogleLoginComponent from "../Components/GoogleLoginComponent";
import CustomModal from '../Components/OtpModal';
import VerifyModal from '../Components/VerifyModal'
import axios from "axios";
import { baseurl } from "../constants";

export default function Login() {

    const navigate = useNavigate();

    const [mobilePrompt, setMobilePrompt] = useState(false);
    const [otpBox, setOtpBox] = useState(false);
    const [nameBox, setNameBox] = useState(false);
    const [prompt, setPrompt] = useState(false);
    const [mobileNumber, setMobileNumber] = useState("");
    const [history_id, setHistoryId] = useState(0);
    const [otp, setOtp] = useState("");
    const [name, setName] = useState("");
    const [error, setError] = useState();



    const validateMobile = (e) => {
        const value = e.replace(/\D/g, '');
        return (/^\d{10}$/.test(value));
    }



    const handleSendOtp = async () => {

        try {
            if (validateMobile(mobileNumber)) {

                setError({ state: false, text: "Please enter a valid mobile number" });

                const response = await sendOtp({ mobile: mobileNumber });

                console.log(response);

                if (response.status === 200) {

                    triggerOtpPrompt();
                    setMobilePrompt(false);

                    setHistoryId(response.data.data.history_id);

                } else {
                    alert(`${response.data.message}`);
                }


            } else {
                setError({ state: true, text: "Please enter a valid mobile number" });
            }
        } catch (err) {
            console.log(err);
            return err.message;
        }
    }



    const triggerOtpPrompt = () => {
        setOtpBox(true);
    }


    const triggerNamePrompt = () => {
        setNameBox(true);
        setOtpBox(false);
    }


    const handleVerifyOtp = async () => {
        try {
            if (otp) {
                const response = await verifyOtp({ otp: otp, mobile: mobileNumber, history_id });
                console.log(response);

                if (response.status === 200 && response.data.success) {
                    localStorage.setItem("authToken", response.data.token);
                    localStorage.setItem("mobile", response.data.data.mobile.toString());
                    if (response.data.data.name) {
                        navigate("/");
                    } else {
                        triggerNamePrompt();
                    }
                } else {
                    alert('Invalid OTP, please try again !')
                }
            }

        } catch (err) {
            alert(err?.response?.data?.message);
            return err.message;
        }
    }

    useEffect(() => {
        console.log({ otp, length: otp.length === 4 });
    }, [otp]);


    const handleSubmitName = async () => {
        try {
            const response = await axios.post(`${baseurl}/api/auth/login/name`, {
                mobile: mobileNumber, name: name
            });
            console.log(response);
            if (response.status === 200) {
                navigate("/");
            }
        } catch (err) {
            alert(err?.response?.data?.message || err.message);
            return err.message;
        }
    }

    return <div className="mc-register-container">

        <img className="mc-cover-image" src="/images/Cricketer.png" alt="" />

        <div className="mc-register-component">
            {/* <img src={" " || "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5O4SaH3D_AUzm0M9Zm2v3rGryM0nrpW5CLw&usqp=CAU"} alt="" /> */}
            <div className="mc-register-form-container">
                {alert.state && <Alert variant={alert?.variant} dismissible>{alert?.text}</Alert>}
                {/* <h2 className="mc-register-form-heading">Login </h2> */}

                <div className="mc-auth-google-button">
                    <GoogleLoginComponent />
                    <div className="mc-login-with-mobile" onClick={() => { setMobilePrompt(true); setPrompt(true) }}>
                        <i class="fa-solid fa-phone"></i>

                        Login With Mobile

                    </div>
                    <br />
                </div>

                {prompt && <div className='mc-mobile-prompt-wrapper'>

                    <div className='mc-mobile-prompt-container'>

                        {mobilePrompt && <>

                            <h2>Please enter your Mobile</h2>

                            <div>
                                <input
                                    className='mc-mobile-input'
                                    type='mobile'
                                    placeholder="Mobile Number"
                                    maxLength={10}
                                    onChange={((e) => {
                                        console.log(e.target.value)
                                        document.querySelector(".mc-mobile-input").value = e.target.value.replace(/\D/g, "");
                                        setMobileNumber(e.target.value.replace(/\D/g, ""));
                                        if (validateMobile(e.target.value)) {
                                            setError({ state: false, text: "Please enter a valid mobile number" });
                                        } else {
                                            setError({ state: true, text: "Please enter a valid mobile number" });
                                        }
                                    })}
                                />
                                {error?.state && <div className='mc-error'>{error.text}</div>}

                                <button className={`mc-btn mc-submit-btn mc-send-otp ${!validateMobile(mobileNumber || "") ? 'mc-disabled' : ''}`} onClick={handleSendOtp}>
                                    Send OTP
                                </button>
                            </div>

                        </>}


                        {otpBox && <>

                            <h2>An OTP has been sent {mobileNumber.slice(0, 6)}XXXX</h2>

                            <div>
                                <input
                                    className='mc-otp-input mc-input'
                                    type='mobile'
                                    placeholder="XXXX"
                                    maxLength={4}
                                    onChange={((e) => {
                                        console.log(e.target.value)
                                        document.querySelector(".mc-otp-input").value = e.target.value.replace(/\D/g, "");
                                        setOtp(e.target.value.replace(/\D/g, ""));
                                    })}
                                />
                                {error?.state && <div className='mc-error'>{error.text}</div>}

                                <button className={`mc-btn mc-submit-btn mc-send-otp ${(`${otp}`).length === 4 ? '' : 'mc-disabled'}`} onClick={handleVerifyOtp}>
                                    Verify OTP
                                </button>
                            </div>

                        </>}


                        {nameBox && <>

                            <h2>What's your name?</h2>

                            <div>
                                <input
                                    className='mc-name-input mc-input'
                                    type='text'
                                    placeholder="Your Name"
                                    maxLength={20}
                                    onChange={((e) => {
                                        console.log(e.target.value)
                                        document.querySelector('.mc-name-input').value = e.target.value.replace(/[^a-zA-Z ]/g, '');
                                        setName(e.target.value.replace(/[^a-zA-Z ]/g, ''));
                                    })}
                                />
                                {error?.state && <div className='mc-error'>{error.text}</div>}

                                <button className='mc-btn mc-submit-btn mc-send-otp' onClick={handleSubmitName}>
                                    Submit
                                </button>
                            </div>

                        </>}

                    </div>

                </div>}


                {/* <h2 className="login-link">New here ? <span className="highlight"><a href="/register"> Register</a></span></h2> */}
                {/* <p className="privacy-policy">By continuing, you agree to [this store] <span className="highlight">Conditions of Use</span> and <span className="highlight">Privacy Notice.</span></p> */}
            </div>
        </div>
    </div >

}