import axios from "axios";
import { baseurl } from "../../constants";
export const sendOtp = async (data) => {
    try {
        const response = await axios.post(`${baseurl}/api/auth/login/send-otp`, data, {
            headers: {
                "authorization": process.env.REACT_APP_ACCESS_KEY
            }
        });
        return response;
    } catch (err) {
        return err.message;
    }
}

export const verifyOtp = async (data) => {
    try {
        const response = await axios.post(`${baseurl}/api/auth/login/verify-otp`, data, {
            headers: {
                "authorization": process.env.REACT_APP_ACCESS_KEY
            }
        });
        return response;
    } catch (err) {
        return err.message;
    }
}

export const loginWithGoogle = async (data) => {
    try {
        const response = await axios.post(`${baseurl}/api/auth/login-with-google`, data, {
            headers: {
                "authorization": process.env.REACT_APP_ACCESS_KEY
            }
        });
        return response;
    } catch (err) {
        return err.message;
    }
}