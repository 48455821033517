import axios from "axios";
import { baseurl } from "../constants";

export const logger = async (name, { tag, data }) => {
    try {

        const response = await axios.post(`${baseurl}/api/log`, {
            name,
            tag,
            data
        });

        return response;
    } catch (err) {
        return err.message;
    }
};
