import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Home } from './Pages/Home';
import Registration from './Pages/Registration';
import Login from './Pages/Login';
import VerifyOtp from './Pages/VerifyOtp';
import { gapi } from "gapi-script";
import { useEffect, useState } from "react";
import Leaderboard from './Pages/Leaderboard';
import { baseurl } from './constants';
import axios from 'axios';

function App() {

  const clientId = "143328160150-f527vle5nel7ph9g5n95gr8q8e40lgj5.apps.googleusercontent.com";

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    function start() {
      gapi.client.init({ clientId });
    }
    gapi.load('client:auth2', start);
  }, [])

  useEffect(() => {
    const token = localStorage.getItem("authToken");

    if (token) {
      setIsAuthenticated(true);
    }
  }, []);


  const refreshToken = async () => {
    try {

      const response = await axios.post(`${baseurl}/api/refreshToken`, {

      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      });

      if (response.status === 200) {
        localStorage.setItem("authToken", response.data.token);
      } else {
        localStorage.removeItem("authToken");
      }

    } catch (err) {
      localStorage.removeItem("authToken");
      if (!window.location.href.includes("/login")) {
        window.location.href = "/login";
      }
      return err.message;
    }
  };


  useEffect(() => {
    refreshToken();
  }, [])


  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/register" element={<Registration />} /> */}
          <Route path="/verify-otp" element={<VerifyOtp />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
