
export default function LabelField({ className, title, type, name, options, label, defaultValue, placeholder, text, alertText, alertVariant, alertIcon, ...rest }) {

    return <div>
        {
            options ? <div className="mc-register-form">
                {label && <label className={`mc-label`}>{label || label.name || ""}</label>}
                <select className={`mc-select-tag ${className}`} name={name || ""} defaultValue={defaultValue && defaultValue} {...rest}>
                    <option value=""></option>
                    {options?.map((item) => {
                        return <option value={item.value || item.name || item || ""}>{item.name || item.value || item || ""}</option>
                    })}
                </select>
            </div>
                :
                <div className={type === "checkbox" ? "mc-register-form flex align-start" : "mc-register-form"}>
                    {label && <label className={`mc-label`}>{label || label.name || ""}</label>}
                    <div className="mc-input-container">
                        <input className={type !== "checkbox" && `mc-input ${className}`} type={type || ""} name={name || ""} placeholder={placeholder || ""} required {...rest} />
                        {alertText && <div className={`mc-input-alert ${alertVariant}`}><span>{alertIcon && <i className={alertIcon}></i>} </span>{`${alertText}`}</div>}
                    </div>
                    {type === "checkbox" && <p className="mc-checkbox-text">{text}</p>}
                </div>
        }

    </div>
}