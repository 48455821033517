import { useEffect, useState } from "react";
import { getLeaderBoardScores } from "../Services/Score/Scores";
import { baseurl } from "../constants";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Leaderboard() {


    const navigate = useNavigate();

    const [weekNo, setWeekNo] = useState(1);


    useEffect(() => {
        if (!localStorage.getItem("authToken")) {
            navigate("/login");
        };

    }, [])

    const [rankings, setRankings] = useState([]);


    const startingDate = new Date("2024-04-26");

    const weeks = Math.ceil(((new Date()).getTime() - startingDate.getTime()) / (1000 * 60 * 60 * 24 * 7));

    console.log({ weeks, array: Array(weeks).fill(0) });

    const [activeTab, setActiveTab] = useState(2);

    const [page, setPage] = useState(1);

    const [showMore, setShowMore] = useState(true);

    const [trigger1, setTrigger1] = useState(false);

    useEffect(() => {
        console.log(activeTab);
        setRankings([]);
        setPage(1);
        setWeekNo(1);
        setTrigger1(!trigger1)
    }, [activeTab]);


    useEffect(() => {
        getScore("overwrite");
    }, [trigger1]);



    useEffect(() => { }, [])

    useEffect(() => { setRankings([]); setPage(1); setTrigger1(!trigger1); }, [weekNo]);


    const getScore = async (e) => {
        try {
            const response = await axios.post(`${baseurl}/api/score/ranking?page=${page}&limit=50`, {
                activeTab,
                weekNo,
            },
                {
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem('authToken')}`
                    }
                });

            console.log({ response });

            if (response.data.success) {

                if (response.data.data.rankings.length < 50) {
                    setShowMore(false);
                } else {
                    setShowMore(true);
                }

                if (e == "overwrite") {


                    setRankings(response.data.data.rankings);
                } else {
                    setRankings([...rankings, ...(response.data.data.rankings)]);
                }
            }



        } catch (err) {
            return err.message;
        }
    };

    useEffect(() => {
        getScore();
    }, [page]);

    useEffect(() => {
        console.log({ rankings });
    }, [rankings]);

    setTimeout(() => {

        if (!localStorage.getItem("authToken")) {
            navigate("/login");
        };

        if (!localStorage.getItem("mobile")) {
            navigate("/login");
        }
    }, 1000);








    return <div className="mc-leaderboard-container">

        <div className="mc-leaderboard-wrapper">
            <img src="/images/leaderboard2.png" className="mc-leaderboard-image" alt="" />
            <div className="mc-score-container ">
                {/* <div className="mc-score-row mc-first-one">
                    <span className="mc-name">{userName}</span>
                    <span className="mc-score">{currRank?.highest_score || ""}</span>
                    <span className="mc-rank">{currRank?.ranking - 1 || "__"}</span>
                </div> */}
                <div className="mc-score-row mc-first-one">
                    {/* <span className={`mc-name ${activeTab === 0 ? "mc-active-tab" : ""}`} onClick={() => { setActiveTab(0) }}>
                        <button type="button" className="mc-btn daily-btn">Daily</button>
                    </span> */}
                    <span className={`mc-name ${activeTab === 1 ? "mc-active-tab" : ""}`} onClick={() => { setActiveTab(1) }}>
                        <div className="mc-week-container mc-btn weekly-btn">
                            <select className="mc-btn" onChange={(e) => { setWeekNo(e.target.value) }}>
                                {
                                    Array(weeks).fill(0).map((item, index) => {
                                        return <option value={index + 1}>{`Week ${index + 1}`}</option>
                                    })
                                }
                            </select>
                        </div>
                    </span>
                    <span className={`mc-name ${activeTab === 2 ? "mc-active-tab" : ""}`} onClick={() => { setActiveTab(2) }}>
                        <button className="mc-btn overall-btn">Overall</button>
                    </span>
                </div>
                {rankings && rankings.map((item, index) => {
                    return <div className="mc-score-row">
                        <span className="mc-name">{item.name}</span>
                        <span className="mc-score">{item.score}</span>
                        <span className="mc-rank">{item.globalRank}</span>
                    </div>
                })}
                {showMore && <div className="mc-score-row">

                    <span className="mc-score" style={{ margin: "0 auto", cursor: "pointer" }} onClick={() => { setPage(page + 1) }}>{`Show more`}</span>

                </div>}
            </div>
        </div>
        <button className="mc-btn mc-back-btn" onClick={() => { navigate("/"); window.location.reload() }} onTouchStart={() => { navigate("/"); window.location.reload() }}>Back</button>
    </div>


}