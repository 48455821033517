import React, { useEffect, useState } from 'react';
import { gapi } from "gapi-script";
import CustomGoogleButton from './GoogleButton';
import { loginWithGoogle, sendOtp, verifyOtp } from '../Services/Auth/Login';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseurl } from '../constants';

function GoogleLoginComponent() {
    const navigate = useNavigate();


    const [mobilePrompt, setMobilePrompt] = useState(false);
    const [otpBox, setOtpBox] = useState(false);
    const [nameBox, setNameBox] = useState(false);
    const [prompt, setPrompt] = useState(false);
    const [mobileNumber, setMobileNumber] = useState("");
    const [history_id, setHistoryId] = useState(0);
    const [otp, setOtp] = useState("");
    const [name, setName] = useState("");
    const [error, setError] = useState();
    const [email, setEmail] = useState("");

    useEffect(() => {
        try {

            gapi.load('auth2', function () {
                gapi.auth2.init({
                    // client_id: '143328160150-f527vle5nel7ph9g5n95gr8q8e40lgj5.apps.googleusercontent.com',
                    client_id: '647445655492-cr1ttna34smsevcv26edavd6ff30tfo6.apps.googleusercontent.com'
                }).then(
                    () => {
                        // Google Sign-In initialization successful
                        // You can now use the Google Sign-In API
                    },
                    (error) => {
                        // Error occurred during initialization
                        console.error('Google Sign-In initialization error:', error);

                        // Handle the error, e.g., show an error message to the user
                    }
                );
            });

        } catch (error) {
            console.log(error);
        }
    }, []);

    const handleGoogleSignIn = () => {
        const auth2 = gapi.auth2.getAuthInstance();
        auth2.signIn().then((googleUser) => {
            console.log(googleUser.getBasicProfile());

            const basicProfile = googleUser.getBasicProfile();
            const googleId = basicProfile.getId();
            const name = basicProfile.getName();
            const email = basicProfile.getEmail();
            const imageUrl = basicProfile.getImageUrl();

            responseGoogle({ imageUrl, googleId, email, name });
        }).catch((error) => {
            // Handle sign-in error
            console.error('Google Sign-In error:', error);

            // You can display an error message to the user or take other appropriate actions
        });
    };

    const responseGoogle = async (response) => {

        try {
            console.log(response);
            const { data } = await loginWithGoogle(response);
            console.log("data", data);
            if (data.success) {
                // localStorage.setItem("authToken", data.token);

                // here if mobile is falsy then prompt the user for mobile,

                setEmail(data?.data.email);


                console.log(`this`, data.data.mobile, data.data.isVerified)

                if (data.data.mobile && data.data.isVerified) {
                    localStorage.setItem("mobile", data.data.mobile.toString());
                    localStorage.setItem("authToken", data.token);
                }


                // navigate("/");

                if (!data?.data.mobile || !data?.data?.isVerified) {

                    setMobilePrompt(true);
                    setPrompt(true);

                } else {

                    if (!data.data.name) {
                        setMobileNumber(data.data.mobile);
                        triggerNamePrompt();
                    } else {

                        console.log("Good to go on play mode.");
                        navigate("/");

                    }
                }



            } else {
                // setAlert({ state: true, text: data.message, variant: "danger" });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const validateMobile = (e) => {
        const value = e.replace(/\D/g, '');
        return (/^\d{10}$/.test(value));
    }



    const handleSendOtp = async () => {

        try {
            if (validateMobile(mobileNumber)) {

                setError({ state: false, text: "Please enter a valid mobile number" });

                const response = await sendOtp({ mobile: mobileNumber, email: email });

                console.log(response);

                if (response.status === 200) {

                    triggerOtpPrompt();

                    setHistoryId(response.data.data.history_id);

                } else {
                    alert(`${response.data.message}`);
                }


            } else {
                setError({ state: true, text: "Please enter a valid mobile number" });
            }
        } catch (err) {
            console.log(err);
            return err.message;
        }
    }



    const triggerOtpPrompt = () => {
        setOtpBox(true);
        setMobilePrompt(false);
    }


    const triggerNamePrompt = () => {
        setNameBox(true);
        setOtpBox(false);
    }


    const handleVerifyOtp = async () => {
        try {
            if (otp) {
                const response = await verifyOtp({ otp: otp, mobile: mobileNumber, history_id });
                console.log(response);

                if (response.status === 200 && response.data.success) {
                    if (response.data.data.name) {
                        localStorage.setItem("authToken", response.data.token);
                        localStorage.setItem("mobile", response.data.data.mobile.toString());
                        navigate("/");
                        setPrompt(false);
                    } else {
                        triggerNamePrompt();
                        setOtpBox(false);
                        setMobilePrompt(false);
                    }
                } else {
                    alert('Invalid OTP, please try again !')
                }
            }

        } catch (err) {
            alert(err?.response?.data?.message);
            return err.message;
        }
    }

    useEffect(() => {
        console.log({ otp, length: otp.length === 4 });
    }, [otp]);


    const handleSubmitName = async () => {
        try {
            const response = await axios.post(`${baseurl}/api/auth/login/name`, {
                mobile: mobileNumber, name: name
            });
            console.log(response);
            if (response.status === 200) {
                navigate("/");
                setPrompt(false);
            }
        } catch (err) {
            alert(err?.response?.data?.message || err.message);
            return err.message;
        }
    }

    return (
        <div>
            <CustomGoogleButton onClick={handleGoogleSignIn} />
            {prompt && <div className='mc-mobile-prompt-wrapper'>

                <div className='mc-mobile-prompt-container'>

                    {mobilePrompt && <>

                        <h2>Please enter your Mobile</h2>

                        <div>
                            <input
                                className='mc-mobile-input'
                                type='mobile'
                                placeholder="Mobile Number"
                                maxLength={10}
                                onChange={((e) => {
                                    console.log(e.target.value)
                                    document.querySelector(".mc-mobile-input").value = e.target.value.replace(/\D/g, "");
                                    setMobileNumber(e.target.value.replace(/\D/g, ""));
                                    if (validateMobile(e.target.value)) {
                                        setError({ state: false, text: "Please enter a valid mobile number" });
                                    } else {
                                        setError({ state: true, text: "Please enter a valid mobile number" });
                                    }
                                })}
                            />
                            {error?.state && <div className='mc-error'>{error.text}</div>}

                            <button className={`mc-btn mc-submit-btn mc-send-otp ${!validateMobile(mobileNumber || "") ? 'mc-disabled' : ''}`} onClick={handleSendOtp}>
                                Send OTP
                            </button>
                        </div>

                    </>}


                    {otpBox && <>

                        <h2>An OTP has been sent {mobileNumber.slice(0, 6)}XXXX</h2>

                        <div>
                            <input
                                className='mc-otp-input mc-input'
                                type='mobile'
                                placeholder="XXXX"
                                maxLength={4}
                                onChange={((e) => {
                                    console.log(e.target.value)
                                    document.querySelector(".mc-otp-input").value = e.target.value.replace(/\D/g, "");
                                    setOtp(e.target.value.replace(/\D/g, ""));
                                })}
                            />
                            {error?.state && <div className='mc-error'>{error.text}</div>}

                            <button className={`mc-btn mc-submit-btn mc-send-otp ${(`${otp}`).length === 4 ? '' : 'mc-disabled'}`} onClick={handleVerifyOtp}>
                                Verify OTP
                            </button>
                        </div>

                    </>}


                    {nameBox && <>

                        <h2>What's your name?</h2>

                        <div>
                            <input
                                className='mc-name-input mc-input'
                                type='text'
                                placeholder="Your Name"
                                maxLength={20}
                                onChange={((e) => {
                                    console.log(e.target.value)
                                    document.querySelector('.mc-name-input').value = e.target.value.replace(/[^a-zA-Z ]/g, '');
                                    setName(e.target.value.replace(/[^a-zA-Z ]/g, ''));
                                })}
                            />
                            {error?.state && <div className='mc-error'>{error.text}</div>}

                            <button className='mc-btn mc-submit-btn mc-send-otp' onClick={handleSubmitName}>
                                Submit
                            </button>
                        </div>

                    </>}

                </div>

            </div>}
        </div>
    );
};

export default GoogleLoginComponent;
