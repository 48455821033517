import React from 'react';



function CustomGoogleButton({ onClick }) {
    return (
        <button className='mc-google-login-btn' onClick={onClick}>
            <img src='/images/google.png' alt='' />
            Login with Google
        </button>
    );
}

export default CustomGoogleButton;
